import { useEffect, useState } from "react";
import 'font-awesome/css/font-awesome.min.css';
import "../assets/css/calculater.css";

const Calculator = () => {

  const [isSelected, setSelected] = useState(false);

  useEffect(() => {

    let display = document.getElementById("screen");
    let buttons = document.getElementsByClassName("button");

    if (localStorage.getItem('calculator-mode') && localStorage.getItem('calculator-mode') === 'dark') {
      setSelected(true)
    } else {
      setSelected(false)
    }

    Array.prototype.forEach.call(buttons, function (button) {
      button.addEventListener('click', function () {

        if (button.textContent !== "=" &&
          button.textContent !== "C" &&
          button.textContent !== "x" &&
          button.textContent !== "÷" &&
          button.textContent !== "√" &&
          button.textContent !== "x²" &&
          button.textContent !== "%" &&
          button.textContent !== "⌫" &&
          button.textContent !== "±" &&
          button.textContent !== "sin" &&
          button.textContent !== "cos" &&
          button.textContent !== "tan" &&
          button.textContent !== "log" &&
          button.textContent !== "ln" &&
          button.textContent !== "x^" &&
          button.textContent !== "x !" &&
          button.textContent !== "π" &&
          button.textContent !== "e" &&
          button.textContent !== "rad"
          && button.textContent !== "∘") {
          if (display.value !== undefined) {
            display.value += button.textContent;
          } else {
            display.value = button.textContent;
          }
        } else if (button.textContent === "=") {
          equals(display);
        } else if (button.textContent === "C") {
          clear(display);
        } else if (button.textContent === "x") {
          multiply(display);
        } else if (button.textContent === "÷") {
          divide(display);
        } else if (button.textContent === "±") {
          plusMinus(display);
        } else if (button.textContent === "⌫") {
          backspace(display);
        } else if (button.textContent === "%") {
          percent(display);
        } else if (button.textContent === "π") {
          pi(display);
        } else if (button.textContent === "x²") {
          square(display);
        } else if (button.textContent === "√") {
          squareRoot(display);
        } else if (button.textContent === "sin") {
          sin(display);
        } else if (button.textContent === "cos") {
          cos(display);
        } else if (button.textContent === "tan") {
          tan(display);
        } else if (button.textContent === "log") {
          log(display);
        } else if (button.textContent === "ln") {
          ln(display);
        } else if (button.textContent === "x^") {
          exponent(display);
        } else if (button.textContent === "x !") {
          factorial(display);
        } else if (button.textContent === "e") {
          exp(display);
        } else if (button.textContent === "rad") {
          radians(display);
        } else if (button.textContent === "∘") {
          degrees(display);
        }
      });
    });
  }, []);

  function syntaxError(display) {
    if (eval(display.value) === SyntaxError || eval(display.value) === ReferenceError || eval(display.value) === TypeError) {
      display.value = "Syntax Error";
    }
  }

  function equals(display) {
    if ((display.value).indexOf("^") > -1) {
      let base = (display.value).slice(0, (display.value).indexOf("^"));
      let exponent = (display.value).slice((display.value).indexOf("^") + 1);
      display.value = eval("Math.pow(" + base + "," + exponent + ")");
    } else {
      display.value = eval(display.value)
      syntaxError(display)
    }
  }

  function clear(display) {
    display.value = "";
  }

  function backspace(display) {
    display.value = display.value.substring(0, display.value.length - 1);
  }

  function multiply(display) {
    display.value += "*";
  }

  function divide(display) {
    display.value += "/";
  }

  function plusMinus(display) {
    if (display.value.charAt(0) === "-") {
      display.value = display.value.slice(1);
    } else {
      display.value = "-" + display.value;
    }
  }

  function factorial(display) {
    var number = 1;
    if (display.value === 0) {
      display.value = "1";
    } else if (display.value < 0) {
      display.value = "undefined";
    } else {
      for (var i = display.value; i > 0; i--) {
        number *= i;
      }
      display.value = number;
    }
  }

  function pi(display) {
    display.value = (display.value * Math.PI);
  }

  function square(display) {
    display.value = eval(display.value * display.value);
  }

  function squareRoot(display) {
    display.value = Math.sqrt(display.value);
  }

  function percent(display) {
    display.value = display.value / 100;
  }

  function sin(display) {
    display.value = Math.sin(display.value);
  }

  function cos(display) {
    display.value = Math.cos(display.value);
  }

  function tan(display) {
    display.value = Math.tan(display.value);
  }

  function log(display) {
    display.value = Math.log10(display.value);
  }

  function ln(display) {
    display.value = Math.log(display.value);
  }

  function exponent(display) {
    display.value += "^";
  }

  function exp(display) {
    display.value = Math.exp(display.value);
  }

  function radians(display) {
    display.value = display.value * (Math.PI / 180);
  }

  function degrees(display) {
    display.value = display.value * (180 / Math.PI);
  }

  const checkHandler = () => {
    if (isSelected === true) {
      localStorage.setItem('calculator-mode', 'light');
      setSelected(false)
    } else {
      localStorage.setItem('calculator-mode', 'dark');
      setSelected(true)
    }
  }

  return (
    <div className={`mod-calculator container-${isSelected ? "dark" : "light"}`} >
      <div className="theme-switcher">
        <label className="switch" htmlFor="bgswitcher">
          <input id="bgswitcher" type="checkbox" onClick={checkHandler} defaultChecked={isSelected} />
          <span className="slider"></span>
        </label>
      </div>
      <form className={`mod-calculator-form form-${isSelected ? "dark" : "light"}`}>
        <input type="text" id="screen" className={`form-input-${isSelected ? "dark" : "light"} form-control-lg`} maxLength="20" />
      </form>
      <div className="d-flex justify-content-between button-row">
        <button id="clear" className={`button triggers clear-${isSelected ? "dark" : "light"}`} type="button">&#67;</button>
        <button id="left-parenthesis" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#40;</button>
        <button id="right-parenthesis" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#41;</button>
        <button id="backspace" className={`button triggers backspace-${isSelected ? "dark" : "light"}`} type="button">&#9003;</button>
        <button id="percentage" type="button" className={`button complex-stuff operand-group operand-group-${isSelected ? "dark" : "light"}`}>%</button>
        <button id="notx" type="button" className={`button complex-stuff operand-group operand-group-${isSelected ? "dark" : "light"}`}>x !</button>
        <button id="xsqr" type="button" className={`button complex-stuff operand-group operand-group-${isSelected ? "dark" : "light"}`}>x^</button>
      </div>

      <div className="d-flex justify-content-between button-row">
        <button id="seven" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>7</button>
        <button id="eight" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>8</button>
        <button id="nine" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>9</button>
        <button id="multiply" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}><small>x</small></button>
        <button id="divide" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#247;</button>
        <button id="ln" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>ln</button>
        <button id="expo" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>e</button>
      </div>

      <div className="d-flex justify-content-between button-row">
        <button id="four" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>4</button>
        <button id="five" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>5</button>
        <button id="six" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>6</button>
        <button id="add" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>+</button>
        <button id="square" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#120;&#178;</button>
        <button id="log" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>log</button>
        <button id="cos" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>cos</button>
      </div>

      <div className="d-flex justify-content-between button-row">
        <button id="one" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>1</button>
        <button id="two" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>2</button>
        <button id="three" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>3</button>
        <button id="subtract" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>-</button>
        <button id="square-root" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#8730;</button>
        <button id="sin" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>sin</button>
        <button id="tan" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>tan</button>
      </div>

      <div className="d-flex justify-content-between button-row">
        <button id="tan" type="button" className={`button basic-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>±</button>
        <button id="zero" type="button" className={`button numbers operand-group operand-group-${isSelected ? "dark" : "light"}`}>0</button>
        <button id="decimal" type="button" className={`button basic-stuff operand-group operand-group-${isSelected ? "dark" : "light"}`}>.</button>
        <button id="equal" className={`button triggers equal-${isSelected ? "dark" : "light"}`} type="button">=</button>
        <button id="pie" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>&#x003C0;</button>
        <button id="oclock" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>∘</button>
        <button id="rad" type="button" className={`button complex-stuff operator-group operator-group-${isSelected ? "dark" : "light"}`}>rad</button>
      </div>
    </div>
  )
}

export default Calculator;
