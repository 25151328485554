import Calculator from "./Calculator";
import "../assets/css/calculater.css";

const FixedPopup = () => {
  return (
    <div className="bottom-fixed-popup">
      <input type="checkbox" id="click" />
      <label className="calculater-icon" htmlFor="click">
        <svg className="icon ts-1p2"><use href="#icon_calculater"></use></svg>
      </label>
      <div className="wrapper">
        <div className="chat-box">
          <Calculator />
        </div>
      </div>
    </div>
  )
}

export default FixedPopup;
